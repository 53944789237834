/* App.css */
body {
  font-family: 'Urbanist', sans-serif;
  background-color: #f0f0f0;
  /* background-image: linear-gradient(to bottom right, #72FFB6, #10D164); */

}
.body {
  font-family: 'Urbanist', sans-serif;
  background: #cbb1b1; /* Assuming the main content background is white */
  box-shadow: inset 0 8px 8px -8px #000; /* Adds a subtle shadow at the top */
}
html {
  scroll-behavior: smooth;
}
.navbar {
  background-color: rgb(21, 18, 18)!important;
  font-weight: var(--bs-body-font-weight);
  /* color: white !important; */
  padding-top: 20px;
  box-shadow: 0 0.5rem 1rem rgba(217, 214, 214, 0.15), inset 0 -1px 0 rgba(255, 255, 255, 0);
  /* border-bottom: 2px solid #333; */
}
.navbar-toggler {
  border: none; /* Remove border if any */
  background: transparent; /* Ensures no background color affects the icon */
}

.navbar-toggler-icon {
  display: inline-block;
  width: 30px;
  height: 3px;
  background-color: white; /* Ensure background-color is transparent */
  position: relative;
  box-shadow: none; /* Remove any shadow effects */
}
.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ffffff; /* Set color to white for visibility */
  transition: all 0.3s;
}

.navbar-toggler-icon::before {
  top: -10px;
}

.navbar-toggler-icon::after {
  top: 10px;
}

.hero-section .container {
  padding-bottom: 100px;
  height: 50vh;
  display: flex;
  flex-direction: column;

  place-content: center;
  height: inherit;
  text-align: center;
  align-items: center;
  /* Centers children horizontally in the container */
  justify-content: center;


}

.bg-main {
  height: 55vh;
  position: relative;
  padding: 50px;
}

.bg-color {
  background-image: linear-gradient(to bottom right, #38A2D7, #561139);
}

.nav-link {
  transition: color 0.3s ease-in-out;
  /* Adjust the timing and easing to your preference */
  color:white;
  font-weight: bold;
}

.nav-link:hover {
  color: #007bff;
  /* Bootstrap's primary blue color */
}

.profile-photo {
  width: 120px;
  height: auto;
  border-radius: 50%;
  margin: 20px;
  align-items: center;
  justify-content: center;
}

/* .home-section {
  text-align: center;
  padding: 40px;
  background-color: #9bc5c4;
} */

.intro-line {
  color: rgb(233, 220, 220);
  font-size: small;
  font-weight: 10;
  margin-bottom: 10px;
}

.name {
  color: white;
  font-size: x-large;
}

.btn {
  font-size: small;
}

.btn-primary {
  background-color:#d54f1a;
  color: white;
  border: none;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  text-decoration: none;
}

.social-links {
  display: flex; /* Ensures the icons are in a row */
  justify-content: center; /* Centers the icons horizontally */
  align-items: center; 
  margin-top: 0px;
   /* Adjust spacing around the social links as needed */ 
  text-align: center; /* Center align the icons */
}

.social-links a {
  margin: 0 10px; 
  color:#ffffff;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.social-links a:hover {
  color: #007bff; /* Change color on hover */
}



.about-section {
  background-color: #f0f0f0;
  /* background-image: linear-gradient(to bottom right, #72FFB6, #10D164); */
  /* background-image: linear-gradient(to bottom right, #38A2D7, #561139); */
  padding: 70px;
}

.about-section h2 {
  font-size: x-large;
  color: #333;
  margin-bottom: 20px;
  margin-right: 100px;
  margin-left: 40px;

  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.about-card {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 12px rgb(161, 164, 161);
  border-radius: 8px;
  margin-bottom: -10px;
  margin-left: 40px; 
  display: inline-block;
}

.about-card p {
  color: #666;
  line-height: 24px;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .about-section, .about-card {
      padding: 10px;
  }

  .progress-bar {
      justify-content: flex-start;
      padding-left: 5px;
  }
}

.hand-wave {
  display: inline-block;

  animation: wave-animation 2s infinite; /* Adjust timing as needed */
  transform-origin: 70% 70%; /* Adjust pivot point of the hand */
  font-size: 24px; /* Adjust size as needed */
}

@keyframes wave-animation {
  0%, 100% {
      transform: rotate(0deg);
  }
  10%, 30%, 50%, 70%, 90% {
      transform: rotate(-10deg);
  }
  20%, 40%, 60%, 80% {
      transform: rotate(10deg);
  }
}

.experience-section {
  width: 80%;
  margin: 20px auto 0; /* Adjusted margin for alignment with Skills */
  padding: 20px 0;
  text-align: left;
}

.experience-section h2 {
  font-size: x-large;
  font-weight: 500;
  color: #333;
  margin-left: 10px; /* Align heading with Skills */
  margin-bottom: 20px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.experience-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-left: 10px; /* Align grid content with the heading */
}

.experience-card {
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 4px solid #e74c3c;
  min-height: 100%; /* Ensures all cards have a similar height */
}

.experience-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.experience-header {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.calendar-icon {
  color: #e74c3c;
  font-size: 1rem; /* Slightly smaller icon size */
  margin-right: 6px;
}

.experience-duration {
  font-size: 0.8rem; /* Reduced font size for date */
  color: #888;
}

.experience-title {
  font-size: 0.95rem; /* Reduced title font size */
  color: #333;
  margin: 0;
  font-weight: 600;
}

.company-name {
  font-weight: 500;
  color: #0044cc;
}

.experience-description {
  padding-left: 15px; /* Reduced padding */
  margin-top: 5px;
  color: #555;
  line-height: 1.4; /* Reduced line height for compact text */
  font-size: 0.85rem; /* Reduced font size for description */
}

.experience-description li {
  margin-bottom: 5px; /* Reduced space between list items */
}

.sub-experience {
  margin-top: 5px; /* Reduce space above sub-experience */
  padding-left: 10px;
  border-left: 2px solid #e74c3c;
}

.sub-role-title {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  margin: 0; /* Remove extra space above sub-role title */
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;
}

.section-heading {
  width: 100%;
  
  font-size: x-large;
    color: #333;
    margin-bottom: 20px;
    margin-right: 0px;
    font-weight:200;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
}

.skill-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.skill-card:hover {
  transform: translateY(-5px);
}

.skill-category {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 8px;
}

.skill-technologies {
  font-size: 0.9rem;
  color: #555;
}

.contact-section {
  padding: 2rem;
  background-color: #f0f0f0;
  margin-top: 2rem;
  border-radius: 10px;
  
}

.contact-heading h2 {
  font-size: x-large;
  font-weight: 500;
  color: #333;
  margin-left: 120px; /* Align heading with Skills */
  margin-bottom: 20px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.contact-heading p {
  font-size: 0.9rem;
  margin-left: 120px;
  color: #666;
  margin-bottom: 1.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-row {
  display: flex;
  gap: 1rem;
  width: 40%;
  margin-left: 400px;
}

.contact-input,
.contact-textarea {
  width: 30%;
  padding: 0.8rem;
  border-radius: 5px;
  box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
  font-size: 0.9rem;
  color: #333;
}

.contact-input {
  flex: 1;
  width: 40%;
  border: 1px solid #252323;
  border-radius: 25px;
  border-color: #ae9494;
}

.contact-textarea {
  height: 50px;
  resize: none;
  width: 50%;
  margin-left: 340px;
  border: 0.1em  solid #ae9494;
  border-radius: 25px;
}

.contact-submit {
  width: 50%;
  padding: 0.8rem;
  background-color: #ff4a4a;
  color: white;
  font-size: 1rem;
  margin-left: 340px;
  border:none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-submit:hover {
  background-color: #e63939;
}

/* adjustment for smaller screen  for about section*/

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .about-section {
    padding: 20px;  /* Increased padding for better spacing */
    background-color: #f0f0f0;  /* Optional: change the background color for better contrast */
    
  }

  .about-section h2 {
    font-size: large;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #333;
    margin: 10px 0;  /* Reduced margin for tighter layout */
    text-align: center;  /* Center alignment of headers */
  }

  .about-card {
    background-color: #ffffff;
    padding: 15px;  /* Adjusted padding */
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);  /* Softer shadow */
    border-radius: 8px;
    margin: 0 auto;  /* Center align the card */
    display: block;  /* Block display to take full width */
    max-width: 100%; /* Ensure card does not exceed the container width */
  }

  .about-card p {
    color: #333;  /* Darker font color for better readability */
    font-size: 16px;  /* Increased font size */
    line-height: 1.5;  /* Increased line height for easier reading */
    text-align: justify;  /* Justified text for a cleaner look */
  }
}

/* Additional responsive adjustments for mobile for skills section */
@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr); /* Show two cards per row on smaller screens */
    gap: 10px;
  }

  .section-heading{
    text-align: center;
    font-size: large;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .skill-card {
    cursor: pointer; /* Indicates the card can be clicked */
    overflow: hidden; /* Keeps the expanding content neat */
  }
  
  .skill-card:not(:last-child) {
    margin-bottom: 10px; /* Spacing between cards */
  }
  

  .skill-category {
    font-size: 0.9rem; /* Reduce font size for headings */
  }

  .skill-technologies {
    font-size: 0.8rem; /* Reduce font size for text */
  }

  .progress-bar {
    font-size: 0.7rem; /* Smaller font size inside the progress bar for readability */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .skills-grid {
    grid-template-columns: 1fr; /* Only one card per row on very small screens */
  }
}

/* mobile view for contact component*/
/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .contact-heading h2, .contact-heading p {
    margin-left: 10px;  /* Adjust left margin for mobile to align text properly */
    margin-right: 10px; /* Add right margin for better text alignment */
    text-align: center; /* Center the text for a better visual appeal on mobile */
  }

  .contact-row {
    flex-direction: column; /* Stack the input fields vertically on smaller screens */
    align-items: center; /* Center-align the stacked fields */
    margin-left: 0; /* Remove large left margin */
    width: auto; /* Allow the row to expand to fit its content */
  }

  .contact-input,
  .contact-textarea,
  .contact-submit {
    width: calc(100% - 20px); /* Full width minus padding for mobile */
    margin-left: 10px; /* Proper left alignment */
    margin-right: 10px; /* Proper right alignment */
  }

  .contact-textarea {
    margin-top: 10px; /* Ensure there's space between the inputs and the textarea */
  }

  .contact-submit {
    margin-top: 10px; /* Space before the submit button */
    margin-bottom: 10px; /* Space after the submit button for easy access */
  }
}
.footer {
  text-align: center;
 font-weight: 500;
}

.footer .heart {
  color: red;
}

/* experience section mobile view */
/* General Styles */
@media (max-width: 768px) {
.experience-section {
    width: 100%; /* Use full width on smaller screens */
    margin: 20px auto 0;
    padding: 20px 10px; /* Reduced side padding */
    text-align: center;
    
}
.experience-section h2{
  font-size: large;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.experience-grid {
    display: grid;
    grid-template-columns: 1fr; /* Stack vertically on small screens */
    gap: 20px;
    margin-left: 10px; /* Align grid content with the heading */
}

.experience-card {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-left: 4px solid #e74c3c;
    min-height: auto; /* Let height auto-adjust */
}

/* Media Queries for larger screens */
@media (min-width: 768px) {
    .experience-section {
        width: 80%;
        padding: 20px 0;
    }

    .experience-grid {
        grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
    }

    .experience-card {
        padding: 10px 15px;
    }
}

/* Adjustments for Text Sizes and Spacing */
.experience-header,
.experience-title,
.experience-duration,
.experience-description {
    font-size: smaller; /* Slightly reduce the font size */
}

.experience-description li {
    margin-bottom: 5px; /* Maintain compact list spacing */
}
}